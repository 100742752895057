import { InjectionToken } from '@angular/core';

export const SVG_SEAT_CLASS = new InjectionToken<string>('SVG_SEAT_SELECTOR');
export const SVG_ZONE_SELECTOR = new InjectionToken<string>('SVG_ZONE_SELECTOR');
export const SVG_TABLE_SELECTOR = new InjectionToken<string>('SVG_TABLE_SELECTOR');
export const SVG_ZONE_GENERAL_SELECTOR = new InjectionToken<string>('SVG_ZONE_GENERAL_SELECTOR');
export const SVG_UNAVAILABLE_CLASS = new InjectionToken<string>('SVG_UNAVAILABLE_CLASS');
export const SVG_SEAT_COURTESY_CLASS = new InjectionToken<string>('SVG_SEAT_COURTESY_CLASS');
export const SVG_SEAT_SELECTED_CLASS = new InjectionToken<string>('SVG_SEAT_SELECTED_CLASS');
export const SVG_SEAT_ORPHAN_CLASS = new InjectionToken<string>('SVG_SEAT_ORPHAN_CLASS');
export const NUMBER_OF_SEATS_SELECTED_PER_TABLE = new InjectionToken<number>('NUMBER_OF_SEATS_SELECTED_PER_TABLE');
export const SVG_SEAT_RESALE_CLASS = new InjectionToken<string>('SVG_SEAT_RESALE_CLASS');
export const SVG_SEAT_RESALE_ATTRIBUTES = new InjectionToken<{
  id: string;
  price: string;
  groupId: string;
}>('SVG_SEAT_RESALE_ATTRIBUTES', {
  factory: () => ({
    id: 'data-resale-listing-id',
    price: 'data-resale-price',
    groupId: 'data-resale-listing-group-id',
  }),
});

export type SeatSelectionContext = 'panel_venue_handling' | 'public_venue_handling';

export enum VenueHandlingType {
  FULL = 'full',
  INDEPENDENT = 'independent',
}

export const VENUE_CONTEXT = new InjectionToken<SeatSelectionContext>('VENUE_CONTEXT');
